export default {
  name: 'button-block',
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
      validator(value) {
        const options = ['button', 'submit', 'reset']
        return options.includes(value)
      }
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isRounded: {
      type: Boolean,
      required: false,
      default: false
    },
    isSecondary: {
      type: Boolean,
      required: false,
      default: false
    },
    isOutline: {
      type: Boolean,
      required: false,
      default: false
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    isExtrasmall: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedClasses() {
      return {
        'button-block--loading': this.isLoading,
        'button-block--rounded': this.isRounded,
        'button-block--outlined': this.isOutline,
        'button-block--secondary': this.isSecondary,
        'button-block--small': this.isSmall,
        'button-block--extrasmall': this.isExtrasmall
      }
    }
  },
  methods: {
    emitEvents() {
      if (!this.isLoading) {
        this.$emit('click')
      }
    }
  }
}
